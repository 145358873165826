import Cookies from 'js-cookie'
import React from "react"; 
import axios from "axios";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../config.js';


class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loginmode: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleResetMode = this.toggleResetMode.bind(this);

  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }


  setToken(userToken) {
      const expires = 60 * 60 * 1000;
      const inOneHour = new Date(new Date().getTime() + expires);
      Cookies.set('access_token', userToken, { expires: inOneHour });
  }


  toggleResetMode(e) {
      e.preventDefault();
      if (this.state.loginmode) {
        this.setState({loginmode: false})
      }
      else {
        this.setState({loginmode: true})
      }
  }


  doLogin(e) {
    const data = {
      username: this.state.email,
      password: this.state.password,
    };

    const qs = new URLSearchParams(data);

    axios
      .post(global.config.remoteURL + "/token", qs, { headers: { "Access-Control-Allow-Origin": "*", "accept": "application/json", "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        let token = response['data']['access_token'];
        this.setToken(token);
        console.log(token);
        window.location.reload(false);
      })
      .catch((error) => {
        alert(error);
      });
    e.preventDefault();
  }



  doReset(e) {
    let myData = {
      email: this.state.email
    };

    axios
      .post(global.config.remoteURL + "/users/reset", myData, { headers: { "Access-Control-Allow-Origin": "*", "accept": "application/json", "Content-Type": "application/json"} })
      .then((response) => {
        alert("Your password is reset and a one time password is sent to your email.");
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(myData));
        alert(error);
      });
    e.preventDefault();
  }



  handleSubmit(e) {
    if (this.state.loginmode) {
      this.doLogin(e);
    }
    else {
      this.doReset(e)
    }
  }

  render() {
    if (this.state.loginmode) {
      return (
        <Container className="p-3">
          <Row>
            <Col></Col>
            <Col>
              <h2>SGM</h2>
            </Col>
            <Col></Col>
          </Row>

          <Row className="mt-5">
            <Col></Col>
            <Col>
              <form onSubmit={this.handleSubmit}>

                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    name="email"
                    type="text"
                    onChange={this.handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    onChange={this.handleChange} />
                  </Form.Group>
                  <Button variant="primary" type="submit">Log in</Button> (<a href="/#" onClick={this.toggleResetMode}>forgot passord?</a>)
              </form>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      );
    }
    else {
      return(
        <Container className="p-3">
          <Row>
            <Col></Col>
            <Col>
              <h2>SGM</h2>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mt-5">
            <Col></Col>
            <Col>
              <form onSubmit={this.handleSubmit}>

                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    name="email"
                    type="text"
                    onChange={this.handleChange} />
                </Form.Group>
                <Button variant="primary" type="submit">Reset password</Button> (<a href="/#" onClick={this.toggleResetMode}>Cancel</a>)
              </form>
            </Col>
            <Col></Col>
          </Row>
        </Container>
        );
    }

  }
}

export default LoginForm;
