import React from "react";
import { Container, Row, Col, Table } from 'react-bootstrap';
import { getLogEntries } from './apicalls.js';
import { getOrganizations } from './apicalls.js';
import '../config.js';



class ListLogEntries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
         loglist : [],
         loglistLoaded : false,
        }
  }

  componentDidMount () {
    try {
      getLogEntries(this, this.props );
      this.interval = setInterval(() => { console.log("Updating!"); getLogEntries(this, this.props) }, 5000);
    } catch (error) {
      console.log(error);
    }
  }
  componentWillUnmount() {
      clearInterval(this.interval);
  }

  componentDidUpdate (prevProps) {
    let changes = false;
    for (const index in prevProps) {
	    if (prevProps[index] !== this.props[index]) {
                    if (index === 'orgmap') continue;
			    changes = true;
		    break;
	    }
    }

    if (changes) {
        getLogEntries(this, this.props );
    }
  }
  



  render() {

    if (this.state.loglistLoaded) {

    return (
     <Table>
      <thead>
        <tr>
          <th>When</th>
          <th>Pri</th>
          <th>What</th>
          <th>Source</th>
          <th>Vessel</th>
          <th>Org</th>
        </tr>
      </thead>
      <tbody className="logtable">
        {
        this.state.loglist.map((entry, index) => { 

         let org = this.props.orgmap.get(entry.organization_id) 
         return (
         <React.Fragment  key={index} >
          <tr key={index} className={"logentrymeta pri" + entry.priority+" "+entry.type}>
            <td>
              {entry.timestamp}
            </td>
            <td>{entry.priority}</td>
            <td>{entry.type}</td>
            <td>{entry.source}</td>
            <td>{entry.vessel ? entry.vessel.callsign : ""}</td>
            <td>{typeof org == 'undefined' ? entry.organization_id : org.name}</td>
          </tr>
          <tr className={"logentrymessage pri"+entry.priority+" "+entry.type}>
            <td colSpan="6">{entry.message}</td>
          </tr>
         </React.Fragment>
          ) }
        )
        }
        </tbody>
        </Table>
      );
    }
    else {
      return <div>loading</div>
    }
  }

}


export class LogEntries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
         orglist : [],
         orglistLoaded : false,
         page : 1,
         limit: 20,
         organization_id: null,
         priority: 0,
         type: null,
         source: null,
        }
  }

  componentDidMount() {
    try {
      getOrganizations(this)
    } catch (error) {
      console.log(error);
    }
  }


  addFilter(event, key) {
     let value = event.target.value === "" ? null : event.target.value;
     const thing  = { [key] : value };
     this.setState(thing)
  }

  render() {

    let offset = (this.state.page - 1) * this.state.limit

    // Show names of orgs in the listing
    let orgmap = new Map()
    this.state.orglist.map((entry, index) => orgmap.set(entry.id, entry));

    return (
    <Container>
      <Row>
        <Col>
          <h2>Log</h2>
        </Col>
        <Col>
        <div className="paginationsection">Page <input min="1" type="number" value={this.state.page} style={{width: "4rem", "textAlign":"center"}} 
                      onChange={(e) => {
                         this.setState( { 'page' : e.target.value } );
                       }
                      } 
    />
             Per page 
             <input min="1" type="number" value={this.state.limit} style={{width: "4rem", "textAlign":"center"}}
                      onChange={(e) => {
                         this.setState( { 'limit' : e.target.value } );
                       }
                      }
    />



          </div>



        </Col>
      </Row>

<Row>
  <div className="filtersection">
    <label> Filter on</label>
    <div className="filters">
        <select onChange={ (e) => this.addFilter(e, 'type') }>
          <option value="">All types</option>
          <option value="Note">Note</option>
          <option value="Error">Error</option>
          <option value="Debug">Debug</option>
        </select>
        <select onChange={ (e) => this.addFilter(e, 'priority') }>
          <option value="">All priorities</option>
          <option value="1">1 (Concern)</option>
          <option value="2">2 (Issue)</option>
          <option value="3">3 (Problem)</option>
          <option value="4">4 (Serious)</option>
          <option value="5">5 (Critical)</option>
        </select>
        <select onChange={ (e) => this.addFilter(e, 'source') }>
          <option value="">All sources</option>
          <option value="directip">Directip</option>
          <option value="system">System</option>
          <option value="soap">Soap</option>
          <option value="user">User</option>
        </select>

        <select onChange={(e) => this.addFilter(e, 'organization_id') } >
          <option value="">All orgs</option>
        { this.state.orglist.map((entry, index) => { 
			return (
					<option key={index} value={entry.id}>{entry.name}</option>
			       )
          }) 
         }
        </select>
   </div>
  </div>

</Row>

      <Row className="mt-5">
        <Col>
          <ListLogEntries orgmap={orgmap} offset={offset} limit={this.state.limit} organization_id={this.state.organization_id} 
                type={this.state.type} source={this.state.source} priority={this.state.priority} 
           />
        </Col>
      </Row>
    </Container>
  );
 } 
}

