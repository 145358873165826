import React from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import { Link } from "react-router-dom";
import { Button, Row, Col, Table } from 'react-bootstrap';
// import { getOrganizations } from './apicalls.js';
import '../config.js';



class SkipperList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        organization_id : 0,
        skipperlist : [],
        skipperlistLoaded: false,
//        orglist: [],
//        orglistLoaded : false
        }
  }

  componentDidMount() {
        this.getData();
/*
        getOrganizations(this);
*/
  }


  getData() {
    let token = Cookies.get('access_token');
    let URL = global.config.remoteURL + "/skippers/?";

    if (typeof this.props.params !== 'undefined') {
      URL += "organization_id=" + this.props.params.org_id;
    }

    axios
      .get(URL, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {

        this.setState({
         skipperlist: response['data'], skipperlistLoaded: true
       });

      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
  }

  addFilter(event, key) {
     let value = event.target.value === "" ? null : event.target.value;
     const thing  = { [key] : value };
     this.setState(thing);
  }


  componentDidUpdate (prevProps, prevState) {
    if (prevState.organization_id !== this.state.organization_id) {
      this.getData();
    }
  }


  render() {

    if (this.state.skipperlistLoaded) {

      let org_id = "";
      if (typeof this.props.params !== 'undefined' && typeof this.props.params.org_id !== 'undefined') {
        org_id = this.props.params.org_id;
      }


    return (
      <>
    <Row>
      <Col>
        <h2>Skippers</h2>
      </Col>
      <Col>
        <Button href={"/skippers/add/" + org_id}>Add Skipper</Button>
      </Col>
    </Row>
      <Table>
      <thead>
        <tr>
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Organization</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.skipperlist.map((skipper, index) => (
            <tr key={index}>
              <td>
                <Link 
                to={`/skippers/${skipper.id}`}
                key={index}
                >{skipper.firstname}</Link>
              </td>
              <td>{skipper.lastname}</td>
              <td>{skipper.email}</td>
              <td>{skipper.phone}</td>
              <td>
                {skipper.organization.name ? (
                  <Link
                  to={`/organizations/${skipper.organization.id}`}
                  key={index+1000}>{skipper.organization.name}</Link>
                  ) : ("")
                }
              </td>
            </tr>
          )
        )
      }
      </tbody>
      </Table>
      </>
    );
  }

}

}

export default SkipperList;
