

import React from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import { Link, Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form, Button,  Alert, Container, Row, Col, Table, Tab, Tabs } from 'react-bootstrap';
import { getVessels, addIridiumJob  } from './apicalls.js';

import '../config.js';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format as dateformat} from 'date-fns/esm';
import {parseISO} from 'date-fns';
import {nb} from 'date-fns/esm/locale'
registerLocale('nb', nb);
setDefaultLocale(nb);



class ListModems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        modemlist : []
        }
  }

  getData() {
    let token = Cookies.get('access_token');
    axios
      .get(global.config.remoteURL + "/modems/", { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {

        this.setState({
         modemlist: response['data']
       });


      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
  }

  componentDidMount() {
        this.getData();
    }

  render() {
    return (
      <Table>
      <thead>
        <tr>
          <th>IMEI</th>
          <th>Vessel</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.modemlist.map((modem, index) => (
          <tr key={index}>
            <td>
              <Link 
               to={`/modems/${modem.imei}`}
               key={index}
              >{modem.imei}</Link>
            </td>
            <td>
              {modem.vessel ? (
                  <Link
                  to={`/vessels/${modem.vessel.id}`}
                  key={index+1000}>{modem.vessel.name}</Link>
                  ) : ("")
                }
            </td>
          </tr>
          )
        )
      }
     </tbody>
     </Table>

    );
  }

}


export function Modems() {
  return (
    <Container>
    <Row>
      <Col>
      <h2>Modems</h2>
      </Col>
      <Col>
       <Button href="/modems/add">Add Modem</Button>
      </Col>
      </Row>

      <Row  className="mt-5">
      <Col>
        <ListModems />
      </Col>
      </Row>
    </Container>

  );
}


class Positions extends React.Component {
  constructor(props) {
    super(props);

    let dateFrom = new Date();
    let dateTo = new Date();

    let storedFrom = null;
    let storedTo = null;

    // Get stuff from sessionStorage if we have manipulated the dates today
    let lastStoredPositionDates = sessionStorage.getItem('positionDatesSet');
    if (lastStoredPositionDates) {
        let today = new Date().toISOString().split('T')[0];
        if (today === lastStoredPositionDates) {
            storedFrom = sessionStorage.getItem('positionsFrom');
            storedTo = sessionStorage.getItem('positionsTo');
        }
    }

    if (storedFrom) {
        dateFrom = parseISO(storedFrom)
    } else {
        dateFrom.setMonth(dateFrom.getMonth() - 2);
    }
    if (storedTo) {
        dateTo = parseISO(storedTo)
    }

    
    this.state = {
      imei: props.params.modem_id,
      positionList : [],
      totalPositions: 0,
      dateFrom: dateFrom,
      dateTo: dateTo
    }
  }


  componentDidMount() {
      this.getPositions();
  }


  getPositions() {
    let imei = this.state.imei;

    let from = Math.floor(this.state.dateFrom.getTime() / 1000);
    let to= Math.floor(this.state.dateTo.getTime() / 1000);
    let token = Cookies.get('access_token');
    axios
      .get(global.config.remoteURL + "/modems/" + imei + "/positions/?dateFrom=" + from + "&dateTo=" + to , { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        this.setState({
         positionList: response['data']['positions'], 
         totalPositions: response['data']['totalpositions'],
       });

      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
  }


   render() {

    const setStartDate = (date) => {
        sessionStorage.setItem('positionsFrom', date.toISOString());
        sessionStorage.setItem('positionDatesSet', new Date().toISOString().split('T')[0]);
        this.setState({'dateFrom' : date}, this.getPositions);
    };
    const setEndDate = (date) =>  {
        let iso = date.toISOString();
        if (new Date().toISOString().split('T')[0] === iso.split('T')[0]) {
            sessionStorage.removeItem('positionsTo');
        } else {
            sessionStorage.setItem('positionDatesSet', new Date().toISOString().split('T')[0]);
            sessionStorage.setItem('positionsTo', date.toISOString());
        }
        this.setState({'dateTo' : date}, this.getPositions);
    };
    const downloadData = () => {
        let data = ['"received";"lat";"long";"course";"speed";"MC";"NAF"'];
        this.state.positionList.map((message, index) => {
          let lat =  message.message.body.Latitude/1000;
          let lng =  message.message.body.Longitude/1000;
          let speed =  message.message.body.VesselSpeed;
          let course =  message.message.body.VesselCourse;
          let mc =  message.message.body.MemCode;
          let received = message.received;
          let naf = message.naf_message[0].NAFmessage.replace(/\/\//g ,"\u200B//");
          data.push(received + ";" + lat + ";" + lng + ";" + speed + ";" + course + ";"+mc + ";\"" + naf + "\"");
          return 0;
         })
         let blob = new Blob([data.join("\n")], {type: "text/csv"});
         let url = window.URL.createObjectURL(blob);
         let a = document.getElementById('downloadhelper');

         a.href = url;
         a.click();
         window.URL.revokeObjectURL(url);
    };


 return(
     <div>
     <div style={{display: 'flex', justifyContent: 'flex-start'}} >
     <Form.Label for="dateFrom">From:
       <DatePicker dateFormat="dd/MM yyyy" id="dateFrom" locale="nb" name="startdate" selected={this.state.dateFrom} onChange={(date) => setStartDate(date)} maxDate={this.state.dateTo} fixedHeight />
    </Form.Label>
    <Form.Label for="dateTo"> To:
       <DatePicker dateFormat="dd/MM yyyy" id="dateTo" locale="nb" name="enddate" selected={this.state.dateTo} onChange={(date) => setEndDate(date)}  minDate={this.state.dateFrom}  fixedHeight />
    </Form.Label>
    </div>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{"alignSelf":"center"}}>Total reports: {this.state.totalPositions}</div>
        <Button  style={{marginLeft: "auto"}}  onClick={downloadData}>Download as CSV</Button>
        <a id="downloadhelper" download="positions.csv" href="#void" style={{"display": "none"}}>download helper link</a>

    </div>

      <Table style={{tableLayout: 'fixed'}}>
      <thead>
        <tr>
          <th>Received</th>
          <th>Lat</th>
          <th>Long</th>
          <th>course</th>
          <th>speed</th>
          <th>MC</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.positionList.map((message, index) => {
          let lat =  message.message.body.Latitude/1000;
          let lng =  message.message.body.Longitude/1000;
          let speed =  message.message.body.VesselSpeed;
          let course =  message.message.body.VesselCourse;
          let mc =  message.message.body.MemCode;
          let formatted_received = dateformat(parseISO(message.received),'dd/MM/yy, HH:mm:ss');
          let errorStatus = (message.naf_message[0] && message.naf_message[0].errorStatus) ? message.naf_message[0].errorStatus : "";
          let sent = (message.naf_message[0] && message.naf_message[0].processed) ? dateformat(parseISO(message.processed),'dd/MM/yy, HH:mm:ss') : ""; 

 
 // Below adds a zero-width breaking space before // parts of the NAF message, this ensures messages break correctly
 // on smaller screens IOK 2022-09-02
          return (
          <>
          <tr key={index}>
            <td>{formatted_received}</td>
            <td>{lat}</td>
            <td>{lng}</td>
            <td>{course}</td>
            <td>{speed}</td>
            <td>{mc}</td>
          </tr>
          <tr style={{borderBottomColor: "#000000"}}>
           <td className="messagecell" colSpan="6" style={{maxWidth: '100%', width: '100%'}}>
            { message.naf_message[0].NAFmessage.replace(/\/\//g ,"\u200B//") }
            {errorStatus ? <div style={{color:"red"}}>{errorStatus}</div> : ''}
            {!sent ? <div style={{color: "blue"}}>Not yet sent</div> : '' }
            </td>
          </tr>
          </>

          )
        }
        )
      }
     </tbody>
     </Table>




     </div>
           );


   }


}

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imei: props.params.modem_id,
      messageList : [],
      totalmessages: 0,
      offset : 0,
      limit : 20,
      messagetype : 'ALL',
      replytype : 'ALL'
    }

  }


  componentDidMount() {
    // modem_id is in reality IMEI here PMB 2022-05-25
    this.getMessages(this.props.params.modem_id);
  }



  getMessages() {
    let imei = this.state.imei;
    let token = Cookies.get('access_token');
    axios
      .get(global.config.remoteURL + "/modems/" + imei + "/messages/?offset=" + this.state.offset + "&limit=" + this.state.limit + "&messagetype=" + this.state.messagetype + '&replytype=' + this.state.replytype , { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        this.setState({
         messageList: response['data']['messages'], // ["fun test 1", "fun test 2"]
         totalmessages: response['data']['totalmessages'],
         firstmessage: response['data']['firstmessage'],
         lastmessage: response['data']['lastmessage']
       });

      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
  }


  render() {

    const handleMessagetypeChange = (e) => {
      this.setState({messagetype: e.target.value}, this.getMessages);
    }

    const handleReplytypeChange = (e) => {
      this.setState({replytype : e.target.value}, this.getMessages);
    }

    const handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
        [name]: value
      }, this.getMessages);
    }

    return(
      <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}} >
        <select onChange={(e) => handleMessagetypeChange(e)}>
          <option value="ALL">All message types</option>
          <option value="AUD">AUD</option>
          <option value="DEP">DEP</option>
          <option value="COE">COE</option>
          <option value="COX">COX</option>
          <option value="DCA">DCA</option>
          <option value="POR">POR</option>
          <option value="TRA">TRA</option>
          <option value="HIA">HIA</option>
          <option value="HIF">HIF</option>
          <option value="HIL">HIL</option>
        </select>

        <select onChange={(e) => handleReplytypeChange(e)}>
          <option value="ALL">All reply types</option>
          <option value="ACK">ACK</option>
          <option value="NAK">NAK</option>
        </select>

        <span>
        Offset:  <input 
          size="3"
          name="offset" 
          placeholder="Offset"
          onChange={(e) => handleInputChange(e)}
          value={this.state.offset}
          />
	</span>

	<span>
        How many: <input 
          size="3"
          name="limit" 
          onChange={(e) => handleInputChange(e)}
          value={this.state.limit}
          />
	</span>

        <span>Total messages: {this.state.totalmessages}</span>

      </div>
      <div>
      <Table style={{tableLayout: 'fixed'}}>
      <thead>
        <tr>
          <th>Received</th>
          <th>id</th>
          <th>RN</th>
          <th>TM</th>
          <th>ACK</th>
          <th>Err</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.messageList.map((message, index) => {
          let formatted_received = dateformat(parseISO(message.received),'dd/MM/yy, HH:mm:ss');
          let ACK = '';
          let err = '';
          if (message.naf_message[0]) {
              for (const reply of message.naf_message[0].replies) {
                  ACK = reply.ACK;
                  err = reply.ErrorNumber ? reply.ErrorNumber : '';
              }
          }
 
 // Below adds a zero-width breaking space before // parts of the NAF message, this ensures messages break correctly
 // on smaller screens IOK 2022-09-02
          return (
          <>
          <tr key={"msg_partA" + message.id}>
              <td>{formatted_received}</td>
            <td>{message.naf_message[0] ? message.naf_message[0].id : ''}</td>
            <td>{message.naf_message[0] ? message.naf_message[0].RN : ''}</td>
            <td>{message.naf_message[0] ? message.naf_message[0].TM : ''}</td>
            <td><span style={{color: (ACK ? "inherit" : "red")}}>{ ACK ? "ACK" : "NAK"  }</span></td>
            <td>{ err }</td>
          </tr>
          <tr style={{borderBottomColor: "#000000"}} key={"msg_partB" + message.id}>
           <td className="messagecell" colSpan="6" style={{maxWidth: '100%', width: '100%'}}>
              {message.naf_message[0] ? message.naf_message[0].NAFmessage.replace(/\/\//g ,"\u200B//") : 'no naf'}
              {
                message.naf_message[0] && message.naf_message[0].replies.map((reply, index) => (
                  <div style={{color:"blue"}} key={index+1000}>
                    {reply.NAFmessage.replace(/\/\//g ,"\u200B//") }
                  </div>
                  )
                )
              }
              {message.naf_message[0] ? <div style={{color:"red"}}>{message.naf_message[0].errorStatus}</div> : ''}
            </td>
          </tr>
          </>

          )
        }
        )
      }
     </tbody>
     </Table>
     </div>
     </div>
      )
  }
}


class ModemProfile extends React.Component {
  constructor(props) {
    super(props);

  // init of state    
    this.state = {
      not_found: false,
      loaded: false,
      editmode: false,
      lastsave: false,
      errorsave: false,
      id: 0,
      imei: '',
      vessel_id: '',
      vessel: '',
      vessellistLoaded: false,
      vessellist: [],
      iridium_checking: false,
      iridium_status: "UNKNOWN",
      iridium_timestamp:  dateformat(Date.now(),'dd/MM/yy, HH:mm:ss'),
      iridium_lastmsm: "",
    };

    // binding functions
    this.enterEditMode = this.enterEditMode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // iridium methods
    this.checkIridiumStatus = this.checkIridiumStatus.bind(this);
    this.activateIridiumSubscription = this.activateIridiumSubscription.bind(this);
    this.deactivateModem = this.deactivateModem.bind(this);
    this.activateModem = this.activateModem.bind(this);
    this.suspendModem = this.suspendModem.bind(this);

    // is this add new?
    if (this.props.params.modem_id === "") {
      console.log("adding new");
    }

  }

	checkIridiumStatus() {
		this.setState({ iridium_checking: true },
			() => {
				addIridiumJob('getModemStatus', {
					args: [this.state.imei],
					oncomplete: (data) => {
						console.log("Iridium status check: %j", data);
						let newstatus = "UNKNOWN";
						let newmsg = "Call failed: " + data['result']['msg'];
			                        let newstamp = dateformat(Date.now(),'dd/MM/yy, HH:mm:ss');
						if (data['status'] === "COMPLETE") {
							newstatus = data['result']['status'];
							newmsg = data['result']['msg'];
						}
						if (newstatus === "PENDING") {
							newmsg += " - checking status in a couple of seconds...";
							this.setState({'iridium_lastmsg': newmsg, 'iridium_timestamp': newstamp, 'iridium_status': newstatus  },
								() => setTimeout( this.checkIridiumStatus, 2000) )
						} else {
							this.setState({ iridium_status: newstatus, iridium_lastmsg: newmsg, iridium_timestamp: dateformat(Date.now(),'dd/MM/yy, HH:mm:ss'), iridium_checking: false });
						}
					}
				})
			});
	}

  activateIridiumSubscription () {
      let yes =  window.confirm("Really activate subscription?");
      if (!yes) return;

      let RC = "";
      if (this.state.vessel && this.state.vessel['callsign']) {
	      RC = this.state.vessel['callsign']
      }
      this.setState({ iridium_checking: true },
              () => {
                  addIridiumJob('activateSubscriber', {
                     args: [this.state.imei, RC],

	             onfailure: (data) => {
                         console.log("Iridium activation failure : %j", data);
                         let newmsg = "Call failed: " + data['result']['msg'];
			 this.setState({ iridium_lastmsg: newmsg, iridium_timestamp: dateformat(Date.now(),'dd/MM/yy, HH:mm:ss'), iridium_checking: false })
		     },
                     onsuccess: (data) => {
                         console.log("Iridium activation success: %j", data);
			 let newmsg = "Subscription activated";
			 let newstamp = dateformat(Date.now(),'dd/MM/yy, HH:mm:ss');
			 let newstatus = data['result']['sbdSubscriberAccount']['accountStatus'];
			 if (newstatus === "PENDING") {
			     newmsg += " - checking status in a couple of seconds...";
    			     this.setState({'iridium_lastmsg': newmsg, 'iridium_timestamp': newstamp, 'iridium_status': newstatus  },
			     () => setTimeout( this.checkIridiumStatus, 3000) )
			 } else {
    			     this.setState({'iridium_checking': false, 'iridium_lastmsg': newmsg, 'iridium_timestamp': newstamp, 'iridium_status': newstatus  })
			 }
                     }
	          })
              });
  }

	deactivateModem() {
            let yes =  window.confirm("Really deactivate modem?");
            if (!yes) return;
		this.setState({ iridium_checking: true },
			() => {
				addIridiumJob('deactivateModem', {
					args: [this.state.imei],
		// We can add the keyword "reason" but we won't

					onfailure: (data) => {
						console.log("Iridium deactivation failure : %j", data);
						let newmsg = "Call failed: " + data['result']['msg'];
						this.setState({ iridium_lastmsg: newmsg, iridium_timestamp: dateformat(Date.now(),'dd/MM/yy, HH:mm:ss'), iridium_checking: false })
					},
					onsuccess: (data) => {
						console.log("Iridium deactivation success: %j", data);
						let newmsg = "Modem deactivated";
						let newstamp = dateformat(Date.now(),'dd/MM/yy, HH:mm:ss');
						let newstatus = "DEACTIVE"
						this.setState({'iridium_checking': false, 'iridium_lastmsg': newmsg, 'iridium_timestamp': newstamp, 'iridium_status': newstatus  })
					}
				})
			});
	}

	activateModem() {
            let yes =  window.confirm("Really activate modem?");
            if (!yes) return;
		this.setState({ iridium_checking: true },
			() => {
				addIridiumJob('activateModem', {
					args: [this.state.imei],

					onfailure: (data) => {
						console.log("Iridium activation failure : %j", data);
						let newmsg = "Call failed: " + data['result']['msg'];
						this.setState({ iridium_lastmsg: newmsg, iridium_timestamp: dateformat(Date.now(),'dd/MM/yy, HH:mm:ss'), iridium_checking: false })
					},
					onsuccess: (data) => {
						console.log("Iridium activation success: %j", data);
						let newmsg = "Modem activated";
						let newstamp = dateformat(Date.now(),'dd/MM/yy, HH:mm:ss');
						let newstatus = "ACTIVE"
						this.setState({'iridium_checking': false, 'iridium_lastmsg': newmsg, 'iridium_timestamp': newstamp, 'iridium_status': newstatus  })
					}
				})
			});
	}
	suspendModem() {
            let yes =  window.confirm("Really suspend service on modem?");
            if (!yes) return;
            return;
		this.setState({ iridium_checking: true },
			() => {
				addIridiumJob('suspendModem', {
					args: [this.state.imei],
					// Could add reason here, won't

					onfailure: (data) => {
						console.log("Iridium suspension failure : %j", data);
						let newmsg = "Call failed: " + data['result']['msg'];
						this.setState({ iridium_lastmsg: newmsg, iridium_timestamp: dateformat(Date.now(),'dd/MM/yy, HH:mm:ss'), iridium_checking: false })
					},
					onsuccess: (data) => {
						console.log("Iridium suspension success: %j", data);
						let newmsg = "Modem suspended ";
						let newstamp = dateformat(Date.now(),'dd/MM/yy, HH:mm:ss');
						let newstatus = "SUSPENDED"
						this.setState({'iridium_checking': false, 'iridium_lastmsg': newmsg, 'iridium_timestamp': newstamp, 'iridium_status': newstatus  })
					}
				})
			});
	}

  getData(modem_id) {
    let token = Cookies.get('access_token');

    axios
      .get(global.config.remoteURL + "/modems/" + modem_id, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        this.setState({
         loaded: true,
         id: response['data']['id'],
         imei: response['data']['imei'],
         vessel_id: response['data']['vessel_id'],
         vessel: response['data']['vessel'],
         iridium_status: response['data']['iridium_status'],
         iridium_timestamp: response['data']['iridium_timestamp'],
         iridium_lastmsg: response['data']['iridium_lastmsg']
      })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
        else if (error.response.status === 404) {
          alert(error.response.data['detail']);
          this.setState({not_found: true});
        }
      });
  }

  saveResponse(response) {
      this.setState({
      id: response['data']['id'],
      imei: response['data']['imei'],
      vessel_id: response['data']['vessel_id'],
      vessel: response['data']['vessel'],
      editmode: false,
      vessellistLoaded: false,
      lastsave: Date.now(),
      loaded: true
          })
   }

  saveData() {
    let token = Cookies.get('access_token');

    let mydata = {
      id:this.state.id,
      imei: this.state.imei,
      vessel_id: this.state.vessel_id
    };

    let my_headers = { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json", 
          "Content-Type": "application/json"}

    if (this.state.id === 0) {
      // we have no id, so we must do a post to insert
      axios
        .post(global.config.remoteURL + "/modems/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          imei: response['data']['imei'],
          vessel_id: response['data']['vessel_id'],
          vessel: response['data']['vessel'],
          editmode: false,
          vessellistLoaded: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
        });

    }
    else {
      axios
        .put(global.config.remoteURL + "/modems/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          imei: response['data']['imei'],
          vessel_id: response['data']['vessel_id'],
          vessel: response['data']['vessel'],
          editmode: false,
          vessellistLoaded: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            // TODO PMB FIXME 2022-04-13
            // we need a way to pass error messages from server to client
            // and a way to print it
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });

          }
        });
    }


  }


  componentDidMount() {
      let modem_id = this.props.params.modem_id;
      if (typeof modem_id !== "undefined") {
        this.getData(modem_id);
      }
      else {
       console.log("no modem id, adding new");
       this.setState({editmode: true});
       getVessels(this);
      }
    }

  enterEditMode() {
    this.setState({editmode: true});
    getVessels(this);
    return;
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    this.saveData();
    event.preventDefault();
  }

  render() {

    if (this.state.not_found) {
      return <Navigate to = {-1} />;
    }

    if (this.state.loaded === false && this.state.editmode === false) {
      return(
        <div>Loading</div>
        )
    }

    if (this.state.editmode === false) {
      let lastsave = '';
      if (this.state.lastsave !== false) {
        console.log("lastsave: " + this.state.lastsave);
        lastsave = <Alert variant="success">Saved</Alert>
      }

      console.log("%j", this.state);


      return (
        <Container>
        <Row  className="mt-5">
        <Col>
        <Table striped bordered hover>
         <tbody>
          <tr>
            <td>IMEI</td>
            <td>{this.state.imei}</td>
          </tr>
          <tr>
            <td>Vessel</td>
            <td>{this.state.vessel ? (
                <Link 
                 to={`/vessels/${this.state.vessel.id}`}
                >{this.state.vessel.name}</Link>
              ) : ("")}</td>
          </tr>
          <tr>
            <td>Iridium</td>
            <td><Row>
                  <Col>{ this.state.iridium_status ? this.state.iridium_status : "UNKNOWN" }</Col>
                  <Col className="d-flex flex-row-reverse">
                     <Button className="btn-sm btn-info" disabled={this.state.iridium_checking} onClick={this.checkIridiumStatus}>{this.state.iridium_checking ? " working..." : "Add status check to queue"}</Button>
                  </Col>
                </Row>
                <Row>{ this.state.iridium_lastmsg ? <i><small>{this.state.iridium_timestamp + ": " + this.state.iridium_lastmsg}</small></i> : ""  }</Row>
            </td>
          </tr>
         </tbody>
        </Table>
        </Col>
        </Row>

        {  (this.state.iridium_status === 'AVAILABLE' || this.state.iridium_status === "DEACTIVE") ? 
         <Row>
	   <Col>
	     <Button className="" disabled={this.state.iridium_checking} onClick={this.activateIridiumSubscription}>{this.state.iridium_checking ? " working..." : "Activate Iridium subscription for this IMEI"}</Button> 
	   </Col> 
         </Row>
         : <></> 
	}
        {  this.state.iridium_status === 'ACTIVE' ? 
         <Row>
	   <Col>
	     <Button className="" disabled={this.state.iridium_checking} onClick={this.deactivateModem}>{this.state.iridium_checking ? " working..." : "Deactivate Iridium for this IMEI"}</Button> 
	   </Col> 
	   <Col className="d-flex flex-row-reverse">
	     <Button className="" disabled={this.state.iridium_checking} onClick={this.suspendModem}>{this.state.iridium_checking ? " working..." : "Suspend Iridium for this IMEI"}</Button> 
	   </Col> 
         </Row>
         : <></> 
	}
        {  this.state.iridium_status === 'SUSPENDED' ? 
         <Row>
	   <Col>
	     <Button className="" disabled={this.state.iridium_checking} onClick={this.activateModem}>{this.state.iridium_checking ? " working..." : "Reactivate Iridium for this IMEI"}</Button> 
	   </Col> 
         </Row>
         : <></> 
	}

        <Row  className="mt-3">
         <Col>
          <Button onClick={this.enterEditMode}>Edit</Button>
         </Col>
        </Row>
        <Row className="mt-3">
         <Col>
          {lastsave}
         </Col>
        </Row>

        </Container>

      );
    }
    else {


      let vesselList = this.state.vessellist.map((v, index) => (
        <option key={index} value={v.id} >{v.name}</option>
      ));

      let errorsave = '';
      if (this.state.errorsave !== false) {
        errorsave = <Alert variant="danger">{this.state.errorsave}</Alert>
      }

      return (
        <div>
          <Form onSubmit={this.handleSubmit}>
        <Container className="mt-5">
          <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formIMEI">
              <Form.Label>IMEI</Form.Label>
              <Form.Control
               name="imei"
               type="text"
               value={this.state.imei}
               onChange={this.handleInputChange} />
            </Form.Group>



            <Form.Group className="mb-3" controlId="formVessel">
              <Form.Label>Vessel</Form.Label>
              <Form.Select
               required="required"
               name="vessel_id"
               type="text"
               value={this.state.vessel_id}
               onChange={this.handleInputChange}
              >
               <option value="">Choose vessel</option>

               {vesselList}

               </Form.Select>
            </Form.Group>

            <Button type="submit">Submit</Button>

            </Col>
           </Row>


        <Row className="mt-3">
         <Col>
          {errorsave}
         </Col>
        </Row>


        </Container>
           </Form>

        </div>
      );
    }


  }

}


export function Modem() {
  // params contain id of user, and is passed in the url. PMB 2021-01-05
  let params = useParams();

  let thetab = 'messages';
  let tabselected = window.location.hash ? window.location.hash.match(/tab:(.+)/) : false;
  if (tabselected && tabselected.length>0) {
    thetab = tabselected[1];
  }


  return (
    <Container>
    <Row>
    <Col>
      <h2>Modem</h2>
    </Col>
    </Row>

    <Row>
      <Col>
      <ModemProfile params={params}/>
      </Col>
    </Row>

    <Row>
     <Col>
      <Tabs defaultActiveKey={thetab}
            id="modem-tabs"
            className="modem-tabs"
            transition={false}
            mountOnEnter={true}
            unmountOnExit={false}
            onSelect = {function (eventkey, eventobj) { 
                if (eventkey === 'messages') {
                    window.history.pushState(null, null, ' ')
                } else {
                    window.history.pushState({}, "", "#tab:"+eventkey);
                }

            }}
            fill
            >
        <Tab eventKey="messages" title="Messages">
        <div>
         <Messages params={params}/>
        </div>
        </Tab>
        <Tab eventKey="positions" title="Positions">
         <div> 
          <Positions params={params} />
         </div>
        </Tab>
      </Tabs>
     </Col>
    </Row>


    </Container>

  );
}

