import axios from "axios";
import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode";
import '../config.js';

export function addIridiumJob(action, {args=[], keywords={}, onsuccess, onfailure, onerror, oncomplete}) {
    let token = Cookies.get('access_token');
    let url = global.config.remoteURL + "/iridiumjob/";


    if (!onsuccess) {
        if (oncomplete) {
            onsuccess = oncomplete;
        } else {
            onsuccess = (data) =>  console.log("Iridium Job " + action +  " success: %j", data);
        }
    }

    if (!onfailure) {
        if (oncomplete) {
            onfailure = oncomplete;
        } else {
            onfailure = (data) => console.log("Iridium Job " + action +  " failure : %j", data);
        }
    }

    if (!onerror) {
        onerror = (response) =>  alert("Error doing iridium job " + action + ": %j", response); 
    }

    let data = {
      action: action,
      parameters: args,
      keywords: keywords
    };

    axios
      .post(url, data, { headers: {
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
        "accept": "application/json",
        "Content-Type": "application/json"} })

      .then((response) => {
          console.log("Iridium Job added: %j", response['data']);
          let jobid = response['data']['id'];
          checkIridiumJob(jobid, onsuccess, onfailure, onerror );
          return;
      })
      .catch((error) => {
        console.log("Error adding iridium job %j", error.response);
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        } else {
            onerror(error.response)
        }
      });
}

function checkIridiumJob(jobid, onsuccess, onfailure, onerror) {
    let token = Cookies.get('access_token');
    let url = global.config.remoteURL + "/iridiumjob/" + jobid;

    function checkit () {
        axios.get(url, 
            { headers: {
                "Authorization": "Bearer " + token,
                "Access-Control-Allow-Origin": "*",
                "accept": "application/json"
             }
            }).then((response) => {
                 let stat = response['data']['status'];
                 console.log("Checking on job status " + stat + " %j", response['data']);

                 if (stat === 'FAILED') {
	             console.log("Calling the failure handler");
                     onfailure(response['data']);
                 } else if (stat === 'COMPLETE') {
                     onsuccess(response['data']);
                 } else {
                     setTimeout(checkit, 3000);
                 }
             })
             .catch((error) => {
                 console.log("Error checking job result: %j ", error.response);
                if (error.response.status === 401) {
                     this.setState({isLoggedIn: false});
                     Cookies.remove('access_token')
                     console.log(error.response.status);
                     window.location.reload(false);
                } else {
                    onerror(error.response);
                }
      });

    }


    setTimeout(checkit, 3000);

}


export function getLogEntries(elem, filterdata) {
    let token = Cookies.get('access_token');

    const offset = filterdata.offset ? filterdata.offset : 0;
    const limit = filterdata.limit ? filterdata.limit : 100;


    let url = global.config.remoteURL + "/log/";
    url = url  + "?offset=" + offset;
    url = url + "&limit=" + limit;

    let keys = ['organization_id', 'type', 'priority', 'message_id', 'vessel_id', 'source']
    for (let key of keys) {
        if (typeof filterdata[key] != "undefined" && filterdata[key] !== null) {
            url = url + "&" + key + "=" + filterdata[key];
        }
    }
  
    axios
      .get(url, { headers: {
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        elem.setState({loglist: response['data'], loglistLoaded: true});
        return;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
}



export function getOrganizations(elem, resellers) {
    let token = Cookies.get('access_token');
    
    let my_params = {}
    if (resellers) {
        my_params.resellers = 1;
    }
    axios
      .get(global.config.remoteURL + "/organization/", {params: my_params, headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
      	elem.setState({orglist: response['data'], orglistLoaded: true});
      	return;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
}

// Get resellers, which are organizations so marked, store in state "resellers".
// same as above otherwise
export function getResellers(elem) {
    let token = Cookies.get('access_token');
    let my_params = {"resellers" : 1 }
    axios
      .get(global.config.remoteURL + "/organization/", {params: my_params, headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
      	elem.setState({resellerlist: response['data'], resellerlistLoaded: true});
      	return;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
}


export function getVessels(elem) {
    let token = Cookies.get('access_token');
    axios
      .get(global.config.remoteURL + "/vessels/", { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
      	elem.setState({vessellist: response['data'], vessellistLoaded: true});
      	return;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
}

export function getResellerId() {
    let token = Cookies.get('access_token');
    let token_data = jwt_decode(token);

    if (token_data.reseller_id) {
        return token_data.reseller_id;
    }
    else return false;
}
