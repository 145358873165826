
import React from "react"; 
import axios from "axios";
import Cookies from 'js-cookie'
import { Link  } from "react-router-dom";
import { Button, Row, Col, Table } from 'react-bootstrap';
import '../config.js';



class AppList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        applist : [],
        applistLoaded: false,
        orglist: [],
        orglistLoaded : false,
        organization_id : 0,
        }
  }

  componentDidMount() {
        this.getData();
/*
        getOrganizations(this);
*/
    }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.organization_id !== this.state.organization_id) {
      this.getData();
    }
  }


  getData() {
    let token = Cookies.get('access_token');
    let URL = global.config.remoteURL + "/apps/?";

    if (typeof this.props.params !== 'undefined') {
      URL += "organization_id=" + this.props.params.org_id;
    }


    axios
      .get(URL, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {

        this.setState({
         applist: response['data'],
         applistLoaded: true
       });

 //       console.log(URL);
 //       console.log(response['data']);

      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
		else {
			console.log("hello" + error.response.status);
		}
      });
  }

  addFilter(event, key) {
     let value = event.target.value === "" ? null : event.target.value;
     const thing  = { [key] : value };
     this.setState(thing);
  }

  render() {

    if (this.state.applistLoaded) {

      let org_id = "";
      if (typeof this.props.params !== 'undefined' && typeof this.props.params.org_id !== 'undefined') {
        org_id = this.props.params.org_id;
      }

      return (

        <>

        <Row>
          <Col>
            <h2>Apps</h2>
          </Col>
          <Col>
            <Button href={"/apps/add/" + org_id}>Add App</Button>
          </Col>
        </Row>


      <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Status</th>
          <th>Organization</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.applist.map((app, index) => (
            <tr key={index}>
              <td>
                <Link 
                to={`/apps/${app.id}`}
                key={index}
                >{app.name}</Link>
              </td>
              <td>{app.email}</td>
              <td>{app.is_active ? 'Active' : 'Inactive'} {app.is_demo ? ' - Demo' : '- Prod'}</td>
              <td>
                {app.organization.name ? (
                  <Link
                  to={`/organizations/${app.organization.id}`}
                  key={index+1000}>{app.organization.name}</Link>
                  ) : ("")
                }
              </td>
            </tr>
          )
        )
      }
      </tbody>
      </Table>

      </>
    );


    }
	else {
		return (
		<>
		Applist not loaded
		</>
		)
	}
  }

}

export default AppList;