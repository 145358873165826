import React from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import { Link, useParams, Navigate } from "react-router-dom";
import { Form, Button, Alert, Container, Row, Col, Table } from 'react-bootstrap';
import { getOrganizations } from './apicalls.js';
import VesselList from "../components/vessellist";
import '../config.js';


export function Vessels() {
  return (
    <Container>
      <Row  className="mt-5">
        <Col>
          <VesselList />
        </Col>
      </Row>
    </Container>

  );
}



class VesselProfile extends React.Component {
  constructor(props) {
    super(props);

  // init of state    
    this.state = {
      loaded: false,
      editmode: false,
      lastsave: false,
      errorsave: false,
      orglistLoaded: false,
      id: 0,
      name: '',
      callsign: '',
      transmitterid: '',
      vessel_length: '',
      phone: '',
      email: '',
      MMSI: '',
      organization_id: '',
      organization: '',
      testvessel: false,
      sendVMS: false,
      modem: [],
      orglist: [],
      polstate: 'ready'
    };

    // binding functions
    this.enterEditMode = this.enterEditMode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendPOL = this.sendPOL.bind(this);

    // is this add new?
    if (this.props.params.vessel_id === "") {
      console.log("adding new");
    }

  }

  getData(vessel_id) {
    let token = Cookies.get('access_token');

    axios
      .get(global.config.remoteURL + "/vessels/" + vessel_id, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        console.log(response);
        this.setState({
         loaded: true,
         not_found: false,
         id: response['data']['id'],
         name: response['data']['name'],
         callsign: response['data']['callsign'],
         transmitterid: response['data']['transmitterid'],
         vessel_length: response['data']['vessel_length'],
         phone: response['data']['phone'],
         email: response['data']['email'],
         MMSI: response['data']['MMSI'],
         testvessel: response['data']['testvessel'],
         sendVMS: response['data']['sendVMS'],
         organization: response['data']['organization'],
         modem: response['data']['modem'],
         organization_id: response['data']['organization_id']
      })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
        else if (error.response.status === 404) {
          alert(error.response.data['detail']);
          this.setState({not_found: true});
        }
      });
  }

  saveResponse(response) {
      this.setState({
      id: response['data']['id'],
      name: response['data']['name'],
      callsign: response['data']['callsign'],
      transmitterid: response['data']['transmitterid'],
      vessel_length: response['data']['vessel_length'],
      phone: response['data']['phone'],
      email: response['data']['email'],
      MMIS: response['data']['MMSI'],
      organization_id: response['data']['organization_id'],
      testvessel: response['data']['testvessel'],
      sendVMS: response['data']['sendVMS'],
      editmode: false,
      orglistLoaded: false,
      lastsave: Date.now(),
      loaded: true
          })
   }

  saveData() {
    let token = Cookies.get('access_token');

    let mydata = {
      id:this.state.id,
      name: this.state.name,
      callsign: this.state.callsign,
      transmitterid: this.state.transmitterid,
      vessel_length: this.state.vessel_length,
      phone: this.state.phone,
      email: this.state.email,
      MMSI: this.state.MMSI,
      testvessel: this.state.testvessel,
      sendVMS: this.state.sendVMS,
      organization_id: this.state.organization_id
    };

    console.log(mydata);

    let my_headers = { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json", 
          "Content-Type": "application/json"}

    if (this.state.id === 0) {

      // we have no id, so we must do a post to insert
      axios
        .post(global.config.remoteURL + "/vessels/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          name: response['data']['name'],
          callsign: response['data']['callsign'],
          transmitterid: response['data']['transmitterid'],
          vessel_length: response['data']['vessel_length'],
          phone: response['data']['phone'],
          email: response['data']['email'],
          MMSI: response['data']['MMSI'],
          testvessel: response['data']['testvessel'],
          sendVMS: response['data']['sendVMS'],
          organization_id: response['data']['organization_id'],
          organization: response['data']['organization'],
          editmode: false,
          orglistLoaded: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }

        });

    }
    else {
      axios
        .put(global.config.remoteURL + "/vessels/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          name: response['data']['name'],
          callsign: response['data']['callsign'],
          transmitterid: response['data']['transmitterid'],
          vessel_length: response['data']['vessel_length'],
          phone: response['data']['phone'],
          email: response['data']['email'],
          MMSI: response['data']['MMSI'],
          testvessel: response['data']['testvessel'],
          sendVMS: response['data']['sendVMS'],
          organization_id: response['data']['organization_id'],
          organization: response['data']['organization'],
          editmode: false,
          orglistLoaded: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            // TODO PMB FIXME 2022-04-13
            // we need a way to pass error messages from server to client
            // and a way to print it
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }

        });
    }


  }


  componentDidMount() {
      let vessel_id = this.props.params.vessel_id;
      let organization_id = this.props.params.organization_id;

      if (typeof organization_id != 'undefined') {
        this.setState({organization_id: organization_id});
      }

      if (typeof vessel_id !== "undefined" && vessel_id !== "add") {
        this.getData(vessel_id);
      }
      else {
       this.setState({editmode: true});
       getOrganizations(this);       
      }
    }

  enterEditMode() {
    this.setState({editmode: true});
    getOrganizations(this);
    return;
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    console.log("Name " + name + " and value %j target checked is %j", value, target.checked);

    this.setState({
      [name]: value
    });
  }

  sendPOL () {
      let yes =  window.confirm("Really send POL?");
      if (yes) {
          let success = (response) => {
              this.setState({'polstate': 'sent'});
              setTimeout( () => this.setState({'polstate': 'ready'}), 1500);
          };
          let error = (error) => {
              this.setState({'polstate': 'error'});
              setTimeout( () => this.setState({'polstate': 'ready'}), 3000);
          };

          this.setState({ 'polstate': 'sending'} , function () {
                  reallySendPOL(this.state.id, success, error);
          });

      } else {
          this.setState({'polstate':'ready'});
      }
  }

  deleteInstall  (id) {
     let yes =  window.confirm("Do you want to delete this installation?");
     if (yes) {
         deleteUnitInstallation(id, (result) => this.getData(this.props.params.vessel_id) );
     } else {
         console.log("Praise merciful caesar, " + id + " lives");
     }
  }

  handleSubmit(event) {
    this.saveData();
    event.preventDefault();
  }

  render() {

    if (this.state.not_found) {
      return <Navigate to = {-1} />;
    }

    if (this.state.loaded === false && this.state.editmode === false) {
      return(
        <div>Loading</div>
        )
    }

    if (this.state.editmode === false && this.state.orglistLoaded === false) {
      let lastsave = '';
      if (this.state.lastsave !== false) {
        lastsave = <Alert variant="success">Saved</Alert>
      }

      return (
        <Container>

        <Row className="mt-5">
         <Col></Col>
         <Col></Col>
         <Col></Col>
         <Col className="text-right">
           <Button disabled={this.state.polstate !== 'ready'}  id='polbutton' onClick={this.sendPOL}>{
             this.state.polstate === 'ready' ? 'Send POL to vessel' :
             this.state.polstate === 'sending' ? 'Sending POL...' :
             this.state.polstate === 'sent' ? 'POL sent' :
             this.state.polstate === 'error' ? 'Error sending POL' :
             'Send POL to vessel' 
             }
           </Button></Col>
        </Row>

        <Row  className="mt-5">
        <Col>
        <Table striped bordered hover>
         <tbody>
          <tr>
           <td>Name</td>
           <td>{this.state.name}</td>
          </tr>
          <tr>
           <td>Call sign</td>
           <td>{this.state.callsign}</td>
          </tr>
          <tr>
           <td>Transmitter id</td>
           <td>{this.state.transmitterid}</td>
          </tr>
          <tr>
           <td>Vessel length (cm)</td>
           <td>{this.state.vessel_length}</td>
          </tr>
          <tr>
           <td>Vessel phone</td>
           <td>{this.state.phone}</td>
          </tr>
          <tr>
           <td>Vessel email</td>
           <td>{this.state.email}</td>
          </tr>
          <tr>
           <td>MMSI</td>
           <td>{this.state.MMSI}</td>
          </tr>
          <tr>
           <td>Organization</td>
           <td>{this.state.organization ? (
                <Link 
                 to={`/organizations/${this.state.organization.id}`}
                >{this.state.organization.name}</Link>
              ) : ("")}</td>
          </tr>

          <tr>
           <td>Test vessel?</td>
           <td>{this.state.testvessel ? "yes" : "no"  }</td>
          </tr>

          <tr>
           <td>Send VMS?</td>
           <td>{this.state.sendVMS? "yes" : "no"  }</td>
          </tr>

         </tbody>
        </Table>
        </Col>
        </Row>
        <Row  className="mt-3">
         <Col>
          <Button onClick={this.enterEditMode}>Edit vessel</Button>
         </Col>
        </Row>
        <Row className="mt-3">
         <Col>
          {lastsave}
         </Col>
        </Row>

        <Row>
          <Col>

          <h3>Modems</h3>
            <Table>
              <thead>
                <tr>
                  <th>IMEI</th>
                  <th>Installation unit</th>
                </tr>
              </thead>
              <tbody>
                {this.state.modem.map((m, index) =>
                (
                  <tr key={index+2000}>
                    <td>
                      <Link
                      to={`/modems/${m.imei}`}
                      >{m.imei}</Link>
                    </td>
                    <td>
                      {m.unit_installation === null &&
                      <div style={{"display":"flex", "justifyContent":"space-between"}} >
                        <span>No installation</span>
                        <Link to={`/vessels/${this.state.id}/${m.id}`} key={index} >
                        <Button>Create installation</Button></Link>
                      </div> }
                  {m.unit_installation != null && m.unit_installation.is_active === true && m.unit_installation.installTime != null && <span style={{ color: 'green'}}> Active modem</span> }
                  {m.unit_installation != null && m.unit_installation.is_active === true && m.unit_installation.installTime === null && <span style={{ color: 'blue'}}> Modem pending activation</span> }
                  {m.unit_installation != null && m.unit_installation.is_active === false && m.unit_installation.errorStatus == null && <div style={{"display":"flex", "justifyContent":"space-between"}} ><span>Inactive modem</span> <Button variant="danger" onClick={() => this.deleteInstall(m.unit_installation.id)}>Delete installation</Button></div>

                  }
                  {m.unit_installation != null && m.unit_installation.errorStatus != null && 
                  <div style={{"display":"flex", "justifyContent":"space-between"}} >
                   <span  style={{ color: 'red'}}>Error: {m.unit_installation.errorStatus}</span> 
                   <Button variant="danger" onClick={() => this.deleteInstall(m.unit_installation.id)}>Delete installation</Button>
                  </div>
                  }
                    </td>
                  </tr>
                  )
                )}

              </tbody>
            </Table>
         </Col>
        </Row>



        </Container>

      );
    }
    else {

      let organizationList = this.state.orglist.map((v, index) => (
        <option key={index} value={v.id} >{v.name}</option>
      ));

      let errorsave = '';
      if (this.state.errorsave !== false) {
        errorsave = <Alert variant="danger">{this.state.errorsave}</Alert>
      }

      return (
        <div>
          <Form onSubmit={this.handleSubmit}>
        <Container className="mt-5">
          <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formVessel">
              <Form.Label>Name</Form.Label>
              <Form.Control
               required="required"
               name="name"
               type="text"
               value={this.state.name}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCallsign">
              <Form.Label>Call sign</Form.Label>
              <Form.Control
               required="required"
               name="callsign"
               type="text"
               value={this.state.callsign}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTransmitterid">
              <Form.Label>Transmitter id</Form.Label>
              <Form.Control
               name="transmitterid"
               type="text"
               value={this.state.transmitterid}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formVessellength">
              <Form.Label>Vessel length (cm)</Form.Label>
              <Form.Control
               name="vessel_length"
               type="text"
               value={this.state.vessel_length}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formVesselphone">
              <Form.Label>Vessel phone</Form.Label>
              <Form.Control
               name="phone"
               type="text"
               value={this.state.phone}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formVesselemail">
              <Form.Label>Vessel email</Form.Label>
              <Form.Control
               name="email"
               type="text"
               value={this.state.email}
               onChange={this.handleInputChange} />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formMMSI">
              <Form.Label>MMSI</Form.Label>
              <Form.Control
               name="MMSI"
               type="number"
               value={this.state.MMSI}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formtestvessel">
              <Form.Label>Testvessel</Form.Label>
              <Form.Check
               name="testvessel"
               type="checkbox"
               checked = { this.state.testvessel }
               value={true}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formsendvms">
              <Form.Label>Send VMS</Form.Label>
              <Form.Check
               name="sendVMS"
               type="checkbox"
               checked = { this.state.sendVMS}
               value={true}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formOrganization">
              <Form.Label>Organization</Form.Label>
              <Form.Select
               required="required"
               name="organization_id"
               type="text"
               value={this.state.organization_id}
               onChange={this.handleInputChange}
              >
               <option value="">Choose organization</option>

               {organizationList}

               </Form.Select>
            </Form.Group>


            <Button type="submit">Submit</Button>

            </Col>
           </Row>


        <Row className="mt-3">
         <Col>
          {errorsave}
         </Col>
        </Row>


        </Container>
           </Form>

        </div>
      );
    }


  }

}


export function Vessel() {
  // params contain id of user, and is passed in the url. PMB 2021-01-05
  let params = useParams();

  return (
    <Container>
    <Row>
    <Col>
      <h2>Vessel</h2>
    </Col>
    </Row>

    <Row>
      <Col>
      <VesselProfile params={params}/>
      </Col>
    </Row>
    </Container>

  );
}

const reallySendPOL = (vesselid, andthen, errorthen) => {
 if (!andthen) andthen = (whatever) => null;
 if (!errorthen) errorthen = (error) => { console.log("%j", error) };


 if (!vesselid) return errorthen(false);
 let token = Cookies.get('access_token');
 let mydata = {};
 let my_headers = {
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*",
          "accept": "application/json",
          "Content-Type": "application/json"};

 console.log("About to send");

 axios
  .post(global.config.remoteURL + "/vessels/" + vesselid + "/POL", mydata, { headers: my_headers })
  .then((response) => {
      console.log("POL: %j", response);
      return setTimeout(() => andthen(response), 1000);
  })
  .catch((error) => {
      if (error.response.status === 401) {
      //            Cookies.remove('access_token')
      console.log(error.response.status);
      window.location.reload(false);          
      }
      else {
          console.log("Error pol: %j", error);
          return errorthen(error);
      }
  });

};

const deleteUnitInstallation = (id, success) => {
    if (!id) return;
    if (!success) success = (whatever) => null;
    let token = Cookies.get('access_token');
    let my_headers = { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json",
          "Content-Type": "application/json"};

      // we have no id, so we must do a post to insert
      axios
        .delete(global.config.remoteURL + "/unitinstallations/" + id,  { headers: my_headers })
        .then((response) => {
            return success(response);
        })
        .catch((error) => {
          if (error.response.status === 401) {
//            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);          }
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            console.log("Error! " + error_msg);
            // Probably signal an error here FIXME IOK
          }

        });
};


class UnitInstallationCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vessel_id: this.props.params.vessel_id,
      modem_id: this.props.params.modem_id,
      installerId: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveData = this.saveData.bind(this);

  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  saveData() {
//    alert(this.state.vessel_id + " " + this.state.modem_id + " " + this.state.installer_id);

    let token = Cookies.get('access_token');

    let mydata = {
      vessel_id: this.state.vessel_id,
      modem_id: this.state.modem_id,
      installerId: this.state.installerId,
      savedState: false
    };

    console.log(mydata);

    let my_headers = { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json", 
          "Content-Type": "application/json"}


      // we have no id, so we must do a post to insert
      axios
        .post(global.config.remoteURL + "/unitinstallations/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          vessel_id: response['data']['vessel_id'],
          modem_id: response['data']['modem_id'],
          lastsave: Date.now(),
          savedState: true
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);          }
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }

        });

  }

  render() {

    if (this.state.savedState) {
      return <Navigate to = {{ pathname: "/vessels/" }} />;
    }

    return (
      <Container>
        <Row>
          <Col>
            <h2>Add Unit Installation</h2>
          </Col>
        </Row>

        <Row>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formTransmitterid">
              <Form.Label>Installer ID</Form.Label>
              <p>This is the name of the authorized installer. Only authorized and registered installers can be used</p>
              <Form.Control
               name="installerId"
               type="text"
               onChange={this.handleInputChange} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={this.saveData}>Save</Button>
          </Col>
        </Row>
      </Container>
    );
  } // end of render()

} // end of class UnitIntallation


export function UnitInstallation() {
  let params = useParams();

  return (
    <Container>
      <Row  className="mt-5">
      <Col>
        <UnitInstallationCreate  params={params}/>
      </Col>
      <Col>
      </Col>
      </Row>
    </Container>

  );
}

