

import React from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import '../config.js';

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
    	username: '',
    	email: '',
    	full_name: ''
    };
  }

  getData() {
    let token = Cookies.get('access_token');
    axios
      .get(global.config.remoteURL + "/users/me", { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        console.log(response);
        this.setState({
         username: response['data']['username'],
         email: response['data']['email'],
         full_name: response['data']['full_name']
     	})
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
  }

  componentDidMount() {
        this.getData();
    }

  render() {
    return (
    	<div>
    	username = {this.state.username}<br />
    	email = {this.state.email}<br />
    	full_name = {this.state.full_name}<br />
    	</div>
    );
  }

}


export default function Profile() {
  return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Profile</h2>

      <MyProfile />

    </main>

  );
}