

import React from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import { Link, Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form, Button, Alert, Container, Row, Col, Table } from 'react-bootstrap';
import { getOrganizations, getResellers, getResellerId } from './apicalls.js';
import VesselList from "../components/vessellist";
import SkipperList from "../components/skipperlist";
import AppList from "../components/applist";
import '../config.js';

class ListOrganizations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
         orglist : [],
         orglistLoaded : false
        }
  }


  componentDidMount() {
    try {
      getOrganizations(this);
    } catch (error) {
      console.log(error);
    }
  }

  render() {

    if (this.state.orglistLoaded) {
    return (
     <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Org#</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
        this.state.orglist.map((org, index) => (
          <tr key={index}>
            <td>
              <Link 
               to={`/organizations/${org.id}`}
               key={index}
              >{org.name}</Link>
            </td>
            <td>{org.orgnr}</td>
            <td>{org.is_reseller ? 'Reseller' : ''}</td>
          </tr>
          )
        )
        }
        </tbody>
        </Table>
      );
    }
    else {
      return <div>loading</div>      
    }
  }

}


export function Organizations() {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Organizations</h2>
        </Col>
        <Col>
         <Button href="/organizations/add">Add Organization</Button>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <ListOrganizations />
        </Col>
      </Row>
    </Container>
  );
}


class OrganizationProfile extends React.Component {
  constructor(props) {
    super(props);

  // init of state    
    this.state = {
      not_found: false,
      loaded: false,
      editmode: false,
      lastsave: false,
      id: 0,
      name: '',
      orgnr: '',
      is_reseller: false,
      reseller_id: null,
      resellerlistLoaded: false,
      resellerlist: []
    };

    // binding functions
    this.enterEditMode = this.enterEditMode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // is this add new?
    if (this.props.params.modem_id === "") {
      console.log("adding new");
    }

  }

  resellerName (id) {
      if (!this.state.resellerlistLoaded) return "" + id;
      let found = this.state.resellerlist.find(element => element.id === id);
      if (found) return found.name;
      return id;
  }

  getData(org_id) {
    let token = Cookies.get('access_token');


    axios
      .get(global.config.remoteURL + "/organization/" + org_id, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        console.log(response);
        this.setState({
         loaded: true,
         id: response['data']['id'],
         name: response['data']['name'],
         orgnr: response['data']['orgnr'],
         is_reseller: response['data']['is_reseller'],
         reseller_id: response['data']['reseller_id']
      })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
        else if (error.response.status === 404) {
          alert(error.response.data['detail']);
          this.setState({not_found: true});
        }
      });
  }

  saveResponse(response) {
      this.setState({
      id: response['data']['id'],
      name: response['data']['name'],
      orgnr: response['data']['orgnr'],
      is_reseller: response['data']['is_reseller'],      
      reseller_id: response['data']['reseller_id'],
      editmode: false,
      lastsave: Date.now(),
      loaded: true
          })
   }

  saveData() {
    let token = Cookies.get('access_token');

    let mydata = {
      id:this.state.id,
      name: this.state.name,
      orgnr: this.state.orgnr,
      is_reseller: this.state.is_reseller
    };

    // if this is a reseller creating/updating an org
    // then the resellers org must be the org of the user
    // PMB 2023-03-30
    // IOK 2023-07-25 this is the reseller id from the users' token, if we have none we're supers
    let resellerId = getResellerId();
    if (resellerId) {
      mydata['reseller_id'] = resellerId;
    } else {
        // Superadmins can set the reseller_id to anything, but it is false for is_resellers
        // IOK empty string or false here means no reseller, so we use 0 to mean "no reseller" - for non-superadmins
        // the value is going to be None on the backend, so it won't update the thing  IOK 2023-07-25
        mydata['reseller_id'] = this.state.reseller_id && !this.state.is_reseller ? this.state.reseller_id : 0;
    }

    console.log("Saving %j", mydata);


    let my_headers = { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json", 
          "Content-Type": "application/json"}

    if (this.state.id === 0) {
      // we have no id, so we must do a post to insert
      axios
        .post(global.config.remoteURL + "/organization/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          name: response['data']['name'],
          orgnr: response['data']['orgnr'],
          is_reseller: response['data']['is_reseller'],
          reseller_id: response['data']['reseller_id'],
          editmode: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
        });

    }
    else {
      axios
        .put(global.config.remoteURL + "/organization/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          name: response['data']['name'],
          orgnr: response['data']['orgnr'],
          is_reseller: response['data']['is_reseller'],
          reseller_id: response['data']['reseller_id'],
          editmode: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          console.log(error);
/*
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
          */
        });
    }


  }

  componentDidMount() {
      let org_id = this.props.params.org_id;
      getResellers(this);
      if (typeof org_id !== "undefined") {
        console.log("org id: " + org_id);
        this.getData(org_id);
      }
      else {
       console.log("no org id, adding new");
       this.setState({editmode: true});        
      }
    }

  enterEditMode() {
    this.setState({editmode: true});
    return;
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    this.saveData();
    event.preventDefault();
  }

  render() {

    if (this.state.not_found) {
      return <Navigate to = {-1} />;
    }

    if (this.state.loaded === false && this.state.editmode === false) {
      return(
        <div>Loading</div>
        )
    }

    if (this.state.editmode === false) {
      let lastsave = '';
      if (this.state.lastsave !== false) {
        console.log("lastsave: " + this.state.lastsave);
        lastsave = <Alert variant="success">Saved</Alert>
      }

      return (
        <Container>
        <Row  className="mt-5">
        <Col>
        <Table striped bordered hover>
         <tbody>
         <tr>
          <td>Name</td>
          <td>{this.state.name}</td>
         </tr>
         <tr>
          <td>Orgnr</td>
          <td>{this.state.orgnr}</td>
         </tr>

         <tr>
          <td>Organization type</td>
          <td>{this.state.is_reseller ? 'Reseller' : 'Customer'}</td>
         </tr>
         
         { !this.state.is_reseller && this.state.reseller_id ?
           <tr>
            <td>Reseller</td>
            <td>{this.state.reseller_id ? this.resellerName(this.state.reseller_id) : "None"}</td>
           </tr> : "" 
         } 

          
         </tbody>
        </Table>
        </Col>
        </Row>
        <Row  className="mt-3">
         <Col>
          <Button onClick={this.enterEditMode}>Edit</Button>
         </Col>
        </Row>
        <Row className="mt-3">
         <Col>
          {lastsave}
         </Col>
        </Row>

        <Row>
          <Col>
            <VesselList params={this.props.params} />
          </Col>
        </Row>

        <Row>
          <Col>
            <SkipperList params={this.props.params} />
          </Col>
        </Row>

        <Row>
          <Col>
            <AppList params={this.props.params} />
          </Col>
        </Row>


        </Container>
      );
    }
    else {
        
      let resellerId = getResellerId();
      let resellerFormElement = '';
      // This is the *users* reseller_id from the access token IOK 2023-07-25
      if (!resellerId) {

            let resellerList = this.state.resellerlist.map((v, index) => (
                <option key={index} value={v.id} >{v.name}</option>
              ));

            resellerFormElement =
            <>
            <Form.Group className="mb-3" controlId="formisreseller">
              <Form.Label>Reseller</Form.Label>
              <Form.Check
               name="is_reseller"
               type="checkbox"
               checked = { this.state.is_reseller }
               value={true}
               onChange={this.handleInputChange} />
               </Form.Group>
            { !this.state.is_reseller ?
            <Form.Group className="mb-3" controlId="formOrganization">
              <Form.Label>Choose reseller of this organization</Form.Label>
              <Form.Select
               name="reseller_id"
               type="text"
               value={this.state.reseller_id}
               onChange={this.handleInputChange}
              >
               <option value="">No reseller</option>

               {resellerList}

               </Form.Select>
               </Form.Group>
      
                  : "" }

            </>
        }
      
      
      return (
        <div>
          <Form onSubmit={this.handleSubmit}>
        <Container>
          <Row  className="mt-5">
          <Col>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
               name="name"
               type="text"
               value={this.state.name}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formOrgnr">
              <Form.Label>Org nr</Form.Label>
              <Form.Control
               name="orgnr"
               type="text"
               value={this.state.orgnr}
               onChange={this.handleInputChange} />
            </Form.Group>

            {resellerFormElement}
            
            <Button type="submit">Submit</Button>

            </Col>
           </Row>
        </Container>
           </Form>

        </div>
      );
    }


  }

}


export function Organization() {
  // params contain id of user, and is passed in the url. PMB 2021-01-05
  let params = useParams();

  return (
    <Container>
    <Row>
      <Col>
        <h2>Organization</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <OrganizationProfile params={params}/>
      </Col>
    </Row>

    </Container>
  );
}



