

import React from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import { Link, useParams, Navigate } from "react-router-dom";
import { Form, Button, Alert, Container, Row, Col, Table } from "react-bootstrap";
import { getResellers, getResellerId } from './apicalls.js';
import '../config.js';

class ListUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        userlist : []
        }
  }

  getData() {
    let token = Cookies.get('access_token');
    axios
      .get(global.config.remoteURL + "/users/", { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {

        this.setState({
         userlist: response['data'] // ["fun test 1", "fun test 2"]
       });

        console.log(response);

      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
  }

  componentDidMount() {
        this.getData();
    }

  render() {

    return (
      <Table>
      <thead>
        <tr>
          <th>Full name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.userlist.map((user, index) => (
          <tr key={index}>
            <td>
              <Link 
               to={`/users/${user.id}`}
               key={index}
              >{user.full_name}</Link>
            </td>
            <td>{user.email}</td>
          </tr>
          )
        )
      }
     </tbody>
     </Table>
    );
  }
}


export function Users() {
  return (
    <Container>
    <Row>
      <Col>
      <h2>Users</h2>
      </Col>
      <Col>
       <Button href="/users/add">Add User</Button>
      </Col>
      </Row>

      <Row  className="mt-5">
      <Col>
        <ListUsers />
      </Col>
      </Row>
    </Container>

  );
}




class UserProfile extends React.Component {
  constructor(props) {
    super(props);

  // init of state    
    this.state = {
      not_found: false,
      loaded: false,
      editmode: false,
      lastsave: false,
      errorsave: false,
      id: 0,
      email: '',
      full_name: '',
      password1: '',
      password2: '',
      organization_id: '',
      resellerlistLoaded: false,
      resellerlist: []
    };

    // binding functions
    this.enterEditMode = this.enterEditMode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getOrgName = this.getOrgName.bind(this);

    // is this add new?
    if (this.props.params.user_id === "") {
      console.log("adding new");
    }

  }


  getOrgName() {
    let org_name = '';
    this.state.resellerlist.forEach((elem) => {
      if (this.state.organization_id === elem.id) {
        org_name = elem.name;
      }
    })
    return org_name;
  }


  getData(user_id) {
    let token = Cookies.get('access_token');

    axios
      .get(global.config.remoteURL + "/users/" + user_id, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        console.log(response);
        this.setState({
         loaded: true,
         id: response['data']['id'],
         email: response['data']['email'],
         full_name: response['data']['full_name'],
         organization_id: response['data']['organization_id']
      })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
        else if (error.response.status === 404) {
          alert(error.response.data['detail']);
          this.setState({not_found: true});
        }
      });
  }


  saveData() {
    let token = Cookies.get('access_token');

    let mydata = {
      id:this.state.id,
      email: this.state.email,
      full_name: this.state.full_name,
    };

    if (this.state.organization_id !== "") {
      mydata['organization_id'] = this.state.organization_id;
    }

    // if this is a reseller creating/updating a user
    // then the resellers org must be the org of the user
    // PMB 2023-03-30
    let resellerId = getResellerId();
    if (resellerId) {
      mydata['organization_id'] = resellerId;
    }



    let pass1 = this.state.password1;
    let pass2 = this.state.password2;
    if (pass1 !== '' || pass2 !== '') {
      if (pass1 !== pass2) {
        alert("Passwords do not match.");
        return;
      }
      else {
        mydata['password'] = pass1;
      }
    }
    else {
      mydata['password'] = "";
    }

    if (this.state.id === 0) {
      // we have no id, so we must do a post to insert
      axios
        .post(global.config.remoteURL + "/users/", mydata, { headers: { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json", 
          "Content-Type": "application/json"} })
        .then((response) => {
          console.log(response);
          this.setState({
          id: response['data']['id'],
          email: response['data']['email'],
          full_name: response['data']['full_name'],
          organization_id: response['data']['organization_id'],
          editmode: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }
        });

    }
    else {
      axios
        .put(global.config.remoteURL + "/users/", mydata, { headers: { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json", 
          "Content-Type": "application/json"} })
        .then((response) => {
          console.log(response);
          this.setState({
          id: response['data']['id'],
          email: response['data']['email'],
          full_name: response['data']['full_name'],
          organization_id: response['data']['organization_id'],
          editmode: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }
        });
    }


  }


  componentDidMount() {
      let user_id = this.props.params.user_id;
      getResellers(this); 
     
      if (typeof user_id !== "undefined") {
        console.log("user id: " + user_id);
        this.getData(user_id);
      }
      else {
       console.log("no user id, adding new");
       this.setState({editmode: true});
      }
    }

  enterEditMode() {
    this.setState({editmode: true});
    return;
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    this.saveData();
    event.preventDefault();
  }

  
  render() {

    // get the name of this users org PMB 2023-04-24
    let org_name = this.getOrgName();

    if (this.state.not_found) {
      return <Navigate to = {-1} />;
    }

    if (this.state.loaded === false && this.state.editmode === false) {
      return(
        <div>Loading</div>
        )
    }

    if (this.state.editmode === false) {
      let lastsave = '';
      if (this.state.lastsave !== false) {
        console.log("lastsave: " + this.state.lastsave);
        lastsave = <Alert variant="success">Saved</Alert>
      }

      return (

       <Container>
        <Row  className="mt-5">
        <Col>
        <Table striped bordered hover>
         <tbody>
          <tr>
           <td>Email</td>
           <td>{this.state.email}</td>
          </tr>
          <tr>
           <td>Full name</td>
           <td>{this.state.full_name}</td>
          </tr>
          <tr>
           <td>Organization</td>
           <td>{org_name}</td>
          </tr>
          </tbody>
        </Table>
        </Col>
        </Row>
        <Row  className="mt-3">
         <Col>
          <Button onClick={this.enterEditMode}>Edit</Button>
         </Col>
        </Row>
        <Row className="mt-3">
         <Col>
          {lastsave}
         </Col>
        </Row>

        </Container>
      );
    }
    else {

      let organizationList = this.state.resellerlist.map((v, index) => (
        <option key={index} value={v.id} >{v.name}</option>
      ));

      let errorsave = '';
      if (this.state.errorsave !== false) {
        errorsave = <Alert variant="danger">{this.state.errorsave}</Alert>
      }

      let pass_required = false;
      if (this.state.id === 0) {pass_required = true;}
      
      let resellerId = getResellerId();
      console.log(resellerId);
      let orgFormElement = '';

      // only admin users that are NOT resellers, can change organisation for a user
      // PMB 2023-04-24
      if (!resellerId) {
            orgFormElement =
            <Form.Group className="mb-3" controlId="formOrganization">
              <Form.Label>Choose organization if this is a reseller</Form.Label>
              <Form.Select
               name="organization_id"
               type="text"
               value={this.state.organization_id}
               onChange={this.handleInputChange}
              >
               <option value="">Choose organization</option>

               {organizationList}

               </Form.Select>
               </Form.Group>;    
      }
      
      return (
        <div>
          <Form onSubmit={this.handleSubmit}>
        <Container className="mt-5">
          <Row>
          <Col>
 
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
               required="required"
               name="email"
               type="text"
               value={this.state.email}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formFullname">
              <Form.Label>Full name</Form.Label>
              <Form.Control
               required="required"
               name="full_name"
               type="text"
               value={this.state.full_name}
               onChange={this.handleInputChange} />
            </Form.Group>

 
            {orgFormElement}

            <Button type="submit">Submit</Button>

            </Col>
            <Col>
            <Form.Group className="mb-3" controlId="formFullname">
              <Form.Label>Password</Form.Label>
              <Form.Control
               required={pass_required}
               name="password1"
               type="password"
               value={this.state.password1}
               onChange={this.handleInputChange} />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formFullname">
              <Form.Label>Repeat Password</Form.Label>
              <Form.Control
               required={pass_required}
               name="password2"
               type="password"
               value={this.state.password2}
               onChange={this.handleInputChange} />
            </Form.Group>


            </Col>
           </Row>

          <Row className="mt-3">
            <Col>
              {errorsave}
            </Col>
          </Row>
        </Container>
           </Form>

        </div>
      );
    }


  }

}


export function User() {
  // params contain id of user, and is passed in the url. PMB 2021-01-05
  let params = useParams();

  return (
    <Container>
    <Row>
    <Col>
      <h2>User</h2>
    </Col>
    </Row>

    <Row>
      <Col>
      <UserProfile params={params}/>
      </Col>
    </Row>
    </Container>

  );
}



