
import React from "react";
import Cookies from 'js-cookie'
import { Button, Container, Row, Col } from "react-bootstrap";


function logmeout() {
	Cookies.remove('access_token');
    window.location.href = '/';
}


export default function Logout() {
  return (
        <Container>
    <Row>
      <Col>
      <h2>Logout</h2>
      </Col>
      <Col>

      </Col>
      </Row>

      <Row  className="mt-5">
      <Col>
        <Button onClick={logmeout}>Really do the logout</Button>
      </Col>
      <Col>
      </Col>
      </Row>
    </Container>


  );
}