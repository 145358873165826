import React from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import { Link, Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form, Button, Alert, Container, Row, Col, Table } from 'react-bootstrap';
import { getOrganizations } from './apicalls.js';
import AppList from "../components/applist";

import '../config.js';


export function Apps() {
  return (
    <Container>
      <Row  className="mt-5">
      <Col>
	  <AppList />
      </Col>
      </Row>
    </Container>

  );
}



class AppProfile extends React.Component {
  constructor(props) {
    super(props);

  // init of state    
    this.state = {
      loaded: false,
      editmode: false,
      deletemode: false,
      deleted: false,
      lastsave: false,
      errorsave: false,
      orglistLoaded: false,
      id: 0,
      name: '',
      email: '',
      organization_id: '',
      is_active: false,
      is_demo: false,
      organization: '',
      orglist: []
    };

    // binding functions
    this.enterEditMode = this.enterEditMode.bind(this);
    this.enterDeleteMode = this.enterDeleteMode.bind(this);
    this.exitDeleteMode = this.exitDeleteMode.bind(this);
    this.deleteOnServer = this.deleteOnServer.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deactivateApp = this.deactivateApp.bind(this);
    this.activateApp = this.activateApp.bind(this);

    // is this add new?
    if (this.props.params.vessel_id === "") {
      console.log("adding new");
    }

  }

  deleteOnServer() {
    let token = Cookies.get('access_token');

    console.log("deleting: " + this.state.id);

    axios
      .delete(global.config.remoteURL + "/apps/" + this.state.id, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        console.log(response);
        this.setState({deleted: true});
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
          if (error.response.status === 404) {
            console.log(error.response.status);
            window.location.reload(false);
          }
        }
      });
  }

  getData(app_id) {
    let token = Cookies.get('access_token');

    axios
      .get(global.config.remoteURL + "/apps/" + app_id, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        console.log(response);
        this.setState({
         loaded: true,
         id: response['data']['id'],
         name: response['data']['name'],
         email: response['data']['email'],
         is_active: response['data']['is_active'],
         is_demo: response['data']['is_demo'],
         organization: response['data']['organization'],
         organization_id: response['data']['organization_id']
      })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
        else if (error.response.status === 404) {
          alert(error.response.data['detail']);
          this.setState({not_found: true});
        }
      });
  }

  saveResponse(response) {
      this.setState({
      id: response['data']['id'],
      name: response['data']['name'],
      email: response['data']['email'],
      organization_id: response['data']['organization_id'],
      is_active: response['data']['is_active'],
      is_demo: response['data']['is_demo'],
      editmode: false,
      orglistLoaded: false,
      lastsave: Date.now(),
      loaded: true
          })
   }

  saveData() {
    let token = Cookies.get('access_token');

    let mydata = {
      id:this.state.id,
      name: this.state.name,
      email: this.state.email,
      is_active: this.state.is_active,
      is_demo: this.state.is_demo,
      organization_id: this.state.organization_id
    };

    console.log(mydata);

    let my_headers = { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json", 
          "Content-Type": "application/json"}

    if (this.state.id === 0) {

      // we have no id, so we must do a post to insert
      axios
        .post(global.config.remoteURL + "/apps/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          name: response['data']['name'],
          email: response['data']['email'],
          is_active: response['data']['is_active'],
          is_demo: response['data']['is_demo'],
          organization_id: response['data']['organization_id'],
          organization: response['data']['organization'],
          editmode: false,
          orglistLoaded: false,
          lastsave: 'Saved',
          loaded: true
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }

        });

    }
    else {
      axios
        .put(global.config.remoteURL + "/apps/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          name: response['data']['name'],
          email: response['data']['email'],
          is_demo: response['data']['is_demo'],
          organization_id: response['data']['organization_id'],
          organization: response['data']['organization'],
          editmode: false,
          orglistLoaded: false,
          lastsave: 'Saved',
          loaded: true
          })
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }

        });
    }


  }


  componentDidMount() {
    let app_id = this.props.params.app_id;
    let organization_id = this.props.params.organization_id;

    if (typeof organization_id != 'undefined') {
      this.setState({organization_id: organization_id});
    }
      
    if (typeof app_id !== "undefined" && app_id !== "add") {
      this.getData(app_id);
    }
    else {
     this.setState({editmode: true});
     getOrganizations(this);       
    }
  }

  enterEditMode() {
    this.setState({editmode: true});
    getOrganizations(this);
    return;
  }

  enterDeleteMode() {
    this.setState({deletemode: true});
    getOrganizations(this);
    return;
  }

  exitDeleteMode() {
    this.setState({editmode: false, deletemode: false, orglistLoaded: false});
    return;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    this.saveData();
    event.preventDefault();
  }

  deactivateApp(event) {
    let retval = window.confirm("Really deactivate this app?");
    if (retval) {
     this.setState({is_active: false}, function () {
        this.saveData();
      });
    }
  }


  activateApp(event) {
    let retval = window.confirm("Really send one time token?");
    if (retval) {
      let token = Cookies.get('access_token');
      axios
      .get(global.config.remoteURL + "/apps/" + this.state.id + "/send_onetime_token/", { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        this.setState({
              lastsave: "Token sent"
            });
        console.log(response);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
//      alert("we will now send a token to the email");
    }
  }



  render() {


    if (this.state.deleted || this.state.not_found) {
      return <Navigate to = {-1} />;
    }

    if (this.state.loaded === false && this.state.editmode === false) {
      return(
        <div>Loading</div>
        )
    }

    if (this.state.editmode === false && this.state.orglistLoaded === false && this.state.deletemode === false) {
      let lastsave = '';
      if (this.state.lastsave !== false) {
        lastsave = <Alert variant="success">{this.state.lastsave}</Alert>
      }

      let de_or_re_activate_button = '';
      if (this.state.is_active === true) {
        de_or_re_activate_button = <Button onClick={this.deactivateApp} variant="danger">Deactivate</Button>
      } else {
        de_or_re_activate_button = <Button onClick={this.activateApp} variant="success">Send Token</Button>        
      }


      return (
        <Container>
        <Row  className="mt-5">
        <Col>
        <Table striped bordered hover>
         <tbody>
          <tr>
           <td>Name</td>
           <td>{this.state.name}</td>
          </tr>
          <tr>
           <td>Email</td>
           <td>{this.state.email}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{this.state.is_active ? 'Active' : 'Inactive'}</td>
          </tr>
          <tr>
            <td>App version</td>
            <td>{this.state.is_demo ? 'Demo' : 'Production'}</td>
          </tr>
          <tr>
           <td>Organization</td>
           <td>{this.state.organization ? (
                <Link 
                 to={`/organizations/${this.state.organization.id}`}
                >{this.state.organization.name}</Link>
              ) : ("")}</td>
          </tr>
         </tbody>
        </Table>
        </Col>
        </Row>
        <Row  className="mt-3">
         <Col>
            <Button onClick={this.enterEditMode}>Edit</Button>
            <Button variant="danger" onClick={this.enterDeleteMode}>Delete</Button>
         </Col>
         <Col>
          {de_or_re_activate_button}
         </Col>
        </Row>
        <Row className="mt-3">
         <Col>
          {lastsave}
         </Col>
        </Row>

        </Container>

      );
    }
    else if (this.state.editmode) {

      let organizationList = this.state.orglist.map((v, index) => (
        <option key={index} value={v.id} >{v.name}</option>
      ));

      let errorsave = '';
      if (this.state.errorsave !== false) {
        errorsave = <Alert variant="danger">{this.state.errorsave}</Alert>
      }

      let is_disabled = false;
      if (this.state.is_active === true) {
        is_disabled = true;
      }

      return (
        <div>
          <Form onSubmit={this.handleSubmit}>
        <Container className="mt-5">
          <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
               required="required"
               name="name"
               type="text"
               value={this.state.name}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
               disabled={is_disabled}
               required="required"
               name="email"
               type="text"
               value={this.state.email}
               onChange={this.handleInputChange} />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formOrganization">
              <Form.Label>Organization</Form.Label>
              <Form.Select
               disabled={is_disabled}
               required="required"
               name="organization_id"
               type="text"
               value={this.state.organization_id}
               onChange={this.handleInputChange}
              >
               <option value="">Choose organization</option>

               {organizationList}

               </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formisdemo">
              <Form.Label>Demo version of app</Form.Label>
              <Form.Check
               name="is_demo"
               type="checkbox"
               checked = { this.state.is_demo }
               value={true}
			   disabled={this.state.id === 0 ? false : true}
               onChange={this.handleInputChange} />
            </Form.Group>

            </Col>
           </Row>

        <Row>
          <Col><Button type="submit">Save</Button></Col>
        </Row>

        <Row className="mt-3">
         <Col>
          {errorsave}
         </Col>
        </Row>


        </Container>
           </Form>

        </div>
      );
    }

    else if (this.state.deletemode) {
      return (
        <Container className="mt-5">
          <Row>
            <Col>
             Are you sure you want to delete the app "{this.state.name}"?
            </Col>
          </Row>
          <Row>
            <Col>
              <Button onClick={this.exitDeleteMode}>Cancel</Button>
              <Button variant="danger" onClick={this.deleteOnServer}>Delete</Button>
            </Col>
          </Row>
        </Container>
      );
    }
  }

}



export function App() {
  // params contain id of user, and is passed in the url. PMB 2021-01-05
  let params = useParams();

  return (
    <Container>
    <Row>
    <Col>
      <h2>App</h2>
    </Col>
    </Row>

    <Row>
      <Col>
        <AppProfile params={params}/>
      </Col>
    </Row>
    </Container>

  );
}

