import React from "react"; 
import axios from "axios";
import Cookies from 'js-cookie'
import { Link } from "react-router-dom";
import { Button, Row, Col, Table } from 'react-bootstrap';
import '../config.js';


class VesselList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organization_id : 0,
      vessellist : [],
      vessellistLoaded: false,
    };
  }

  componentDidMount() {
    this.getData();
/*
        getOrganizations(this);
*/
  }

  getData() {
    let token = Cookies.get('access_token');
    let URL = global.config.remoteURL + "/vessels/?";

    if (typeof this.props.params !== 'undefined') {
      URL += "organization_id=" + this.props.params.org_id;
    }


    axios
      .get(URL, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        this.setState({
         vessellist: response['data'], 
         vessellistLoaded: true
       });
        console.log(response['data']);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({isLoggedIn: false});
          Cookies.remove('access_token')
          console.log(error.response.status);
          window.location.reload(false);
        }
      });
  }


  render() {

    if (this.state.vessellistLoaded) {

      let org_id = "";
      if (typeof this.props.params !== 'undefined' && typeof this.props.params.org_id !== 'undefined') {
        org_id = this.props.params.org_id;
      }

    return (

      <>
    <Row>
      <Col>
      <h2>Vessels</h2>
      </Col>
      <Col>
       <Button href={"/vessels/add/" + org_id}>Add Vessel</Button>
      </Col>
      </Row>
      <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Callsign</th>
          <th>Modem // Installation</th>
          <th>Testvessel</th>
          <th>VMS</th>
          <th>Organization</th>
        </tr>
      </thead>
      <tbody>
      {
        this.state.vessellist.map((vessel, index) => (
            <tr key={index}>
              <td>
                <Link 
                to={`/vessels/${vessel.id}`}
                key={index}
                >{vessel.name}</Link>
              </td>
              <td>{vessel.callsign}</td>
              <td>
                {vessel.modem.map((m, index) => (
                  <div key={index+100}>
                  {m.unit_installation === null && <span>Modem without installation</span>}
                  {m.unit_installation != null && m.unit_installation.is_active === true && m.unit_installation.installTime != null && <span style={{ color: 'green'}}> Active modem</span> }
                  {m.unit_installation != null && m.unit_installation.is_active === true && m.unit_installation.installTime === null && <span style={{ color: 'blue'}}> Modem pending activation</span> }
                  {m.unit_installation != null && m.unit_installation.is_active === false && m.unit_installation.errorStatus == null && <span> Inactive modem</span> }
                  {m.unit_installation != null && m.unit_installation.errorStatus != null && <span  style={{ color: 'red'}}>Error: {m.unit_installation.errorStatus}</span> }
                  </div>
                  )
                )}
                {vessel.modem && vessel.modem.length === 0 && (
                  <div>No modem</div>
                )}
              </td>
              <td> { vessel.testvessel ? "yes" : "no" } </td>
              <td> { vessel.sendVMS ? "yes" : "no" } </td>
              <td>
                {vessel.organization.name ? (
                  <Link
                  to={`/organizations/${vessel.organization.id}`}
                  key={index+1000}>{vessel.organization.name}</Link>
                  ) : ("")
                }
              </td>
            </tr>
        ))
      }
      
      </tbody>
      </Table>
      </>
    )
  }
  }
}


export default VesselList;
