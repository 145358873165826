import React from 'react';
import { 
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Cookies from 'js-cookie'
// import Container from 'react-bootstrap/Container';

import { Nav, Navbar, Container } from 'react-bootstrap';

import Profile from "./routes/profile";
import Logout from "./routes/logout";
import { Users, User } from "./routes/users";
import { Organizations, Organization} from "./routes/organizations"
import { Modems, Modem} from "./routes/modems";
import { Vessels, Vessel, UnitInstallation } from "./routes/vessels";
import { Skippers, Skipper } from "./routes/skippers";
import { Apps, App } from "./routes/apps";
import { LogEntries } from "./routes/log";
import LoginForm from "./components/loginform";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';


function Home() {

  return <h2>Home</h2>;
}

function Main() {

  let access_token = Cookies.get('access_token');

  if (!access_token) { return (
       <Container className="mt-5">
      <div> <LoginForm /></div>
      </Container>
    );
  }
  else 
  return (

  <Router>


<Navbar bg="light" expand="lg">
  <Container>
    <Navbar.Brand href="/">SGM</Navbar.Brand>

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">
      <Nav className="me-auto">
 
        <Nav.Link href="/organizations">Organizations</Nav.Link>
        <Nav.Link href="/skippers">Skippers</Nav.Link>
        <Nav.Link href="/vessels">Vessels</Nav.Link>
        <Nav.Link href="/apps">Apps</Nav.Link>
        <Nav.Link href="/modems">Modems</Nav.Link>
        <Nav.Link href="/log">Log</Nav.Link>
        <Nav.Link href="/users">Admins</Nav.Link>
        <Nav.Link href="/logout">Log out</Nav.Link>

      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

    <Container className="mt-5">

    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/add" element={<User />} />
      <Route path="/users/:user_id" element={<User />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/organizations" element={<Organizations />} />
      <Route path="/organizations/:org_id" element={<Organization />} />
      <Route path="/organizations/add" element={<Organization />} />
      <Route path="/skippers" element={<Skippers />} />
      <Route path="/skippers/add/:organization_id" element={<Skipper />} />
      <Route path="/skippers/:skipper_id" element={<Skipper />} />

      <Route path="/apps" element={<Apps />} />
      <Route path="/apps/add/:organization_id" element={<App />} />
      <Route path="/apps/:app_id" element={<App />} />

      <Route path="/log" element={<LogEntries />} />

      <Route path="/vessels" element={<Vessels />} />
      <Route path="/vessels/add/:organization_id" element={<Vessel />} />
      <Route path="/vessels/:vessel_id" element={<Vessel />} />
      <Route path="/vessels/:vessel_id/:modem_id" element={<UnitInstallation />} />

      <Route path="/modems" element={<Modems />} />
      <Route path="/modems/add" element={<Modem />} />
      <Route path="/modems/:modem_id" element={<Modem />} />
      <Route path="/" element={<Home />} />
    </Routes>

    </Container>

  </Router>

 );
}

export default Main;

