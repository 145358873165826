import React from "react";
import axios from "axios";
import Cookies from 'js-cookie'
import { Link, Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form, Button, Alert, Container, Row, Col, Table } from 'react-bootstrap';
import { getOrganizations } from './apicalls.js';
import SkipperList from "../components/skipperlist";
import '../config.js';


export function Skippers() {
  return (
    <Container>

      <Row  className="mt-5">
      <Col>
        <SkipperList />
      </Col>
      </Row>
    </Container>

  );
}



class SkipperProfile extends React.Component {
  constructor(props) {
    super(props);

  // init of state    
    this.state = {
      loaded: false,
      not_found: false,
      editmode: false,
      deletemode: false,
      lastsave: false,
      errorsave: false,
      orglistLoaded: false,
      id: 0,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      organization_id: '',
      organization: '',
      orglist: []
    };

    // binding functions
    this.enterEditMode = this.enterEditMode.bind(this);
    this.enterDeleteMode = this.enterDeleteMode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // is this add new?
    if (this.props.params.vessel_id === "") {
      console.log("adding new");
    }

  }

  getData(skipper_id) {
    let token = Cookies.get('access_token');

    axios
      .get(global.config.remoteURL + "/skippers/" + skipper_id, { headers: { 
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*", 
        "accept": "application/json", 
        "Content-Type": "application/x-www-form-urlencoded"} })
      .then((response) => {
        console.log(response);
        this.setState({
          loaded: true,
         id: response['data']['id'],
         firstname: response['data']['firstname'],
         lastname: response['data']['lastname'],
         email: response['data']['email'],
         phone: response['data']['phone'],
         organization: response['data']['organization'],
         organization_id: response['data']['organization_id']
      })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Cookies.remove('access_token');
          console.log(error.response.status);
          window.location.reload(false);
        }
        else if (error.response.status === 404) {
          alert(error.response.data['detail']);
          this.setState({not_found: true});
        }

      });
  }

  saveResponse(response) {
      this.setState({
      id: response['data']['id'],
      firstname: response['data']['firstname'],
      lastname: response['data']['lastname'],
      organization_id: response['data']['organization_id'],
      editmode: false,
      orglistLoaded: false,
      lastsave: Date.now(),
      loaded: true
          })
   }

  saveData() {
    let token = Cookies.get('access_token');

    let mydata = {
      id:this.state.id,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      organization_id: this.state.organization_id
    };

    console.log(mydata);

    let my_headers = { 
          "Authorization": "Bearer " + token,
          "Access-Control-Allow-Origin": "*", 
          "accept": "application/json", 
          "Content-Type": "application/json"}

    if (this.state.id === 0) {

      // we have no id, so we must do a post to insert
      axios
        .post(global.config.remoteURL + "/skippers/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          firstname: response['data']['firstname'],
          lastname: response['data']['lastname'],
          email: response['data']['email'],
          phone: response['data']['phone'],
          organization_id: response['data']['organization_id'],
          organization: response['data']['organization'],
          editmode: false,
          orglistLoaded: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Cookies.remove('access_token')
            console.log(error.response.status);
            window.location.reload(false);
          }
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }

        });

    }
    else {
      axios
        .put(global.config.remoteURL + "/skippers/", mydata, { headers: my_headers })
        .then((response) => {
          this.setState({
          id: response['data']['id'],
          firstname: response['data']['firstname'],
          lastname: response['data']['lastname'],
          email: response['data']['email'],
          phone: response['data']['phone'],
          organization_id: response['data']['organization_id'],
          organization: response['data']['organization'],
          editmode: false,
          orglistLoaded: false,
          lastsave: Date.now(),
          loaded: true
          })
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            let error_msg = '';
            error.response.data.detail.forEach((elem)=>{
              error_msg += elem.msg;
              console.log(elem.msg);
            });
            this.setState({
              errorsave: error_msg
            });
          }

        });
    }


  }


  componentDidMount() {
      let skipper_id = this.props.params.skipper_id;
      let organization_id = this.props.params.organization_id;


      if (typeof organization_id !== 'undefined') {
        this.setState({organization_id: organization_id});
      }

      if (typeof skipper_id !== "undefined" && skipper_id !== "add") {
        this.getData(skipper_id);
      }
      else {
        this.setState({editmode: true});
        getOrganizations(this);       
      }
    }

  enterEditMode() {
    this.setState({editmode: true});
    getOrganizations(this);
    return;
  }


  enterDeleteMode() {
    this.setState({deletemode: true});
//    getOrganizations(this);
    return;
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    this.saveData();
    event.preventDefault();
  }

  render() {

    if (this.state.not_found) {
      return <Navigate to = {-1} />;
    }

    if (this.state.loaded === false && this.state.editmode === false) {
      return(
        <div>Loading</div>
        )
    }

    if (this.state.editmode === false && this.state.orglistLoaded === false && this.state.deletemode === false) {
      let lastsave = '';
      if (this.state.lastsave !== false) {
        lastsave = <Alert variant="success">Saved</Alert>
      }

      return (
        <Container>
        <Row  className="mt-5">
        <Col>
        <Table striped bordered hover>
         <tbody>
          <tr>
           <td>Firstname</td>
           <td>{this.state.firstname}</td>
          </tr>
          <tr>
           <td>Lastname</td>
           <td>{this.state.lastname}</td>
          </tr>
          <tr>
           <td>Email</td>
           <td>{this.state.email}</td>
          </tr>
          <tr>
           <td>Phone</td>
           <td>{this.state.phone}</td>
          </tr>
          <tr>
           <td>Organization</td>
           <td>{this.state.organization ? (
                <Link 
                 to={`/organizations/${this.state.organization.id}`}
                >{this.state.organization.name}</Link>
              ) : ("")}</td>
          </tr>
         </tbody>
        </Table>
        </Col>
        </Row>
        <Row  className="mt-3">
         <Col>
          <Button onClick={this.enterEditMode}>Edit</Button>
          <Button variant="danger" onClick={this.enterDeleteMode}>Delete</Button>
         </Col>
        </Row>
        <Row className="mt-3">
         <Col>
          {lastsave}
         </Col>
        </Row>

        </Container>

      );
    }
    else if (this.state.deletemode === true) {
      return(
        <div>Really delete?</div>
        )
    }
    else {

      let organizationList = this.state.orglist.map((v, index) => (
        <option key={index} value={v.id} >{v.name}</option>
      ));

      let errorsave = '';
      if (this.state.errorsave !== false) {
        errorsave = <Alert variant="danger">{this.state.errorsave}</Alert>
      }

      return (
        <div>
          <Form onSubmit={this.handleSubmit}>
        <Container className="mt-5">
          <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formFirstname">
              <Form.Label>Firstname</Form.Label>
              <Form.Control
               required="required"
               name="firstname"
               type="text"
               value={this.state.firstname}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastname">
              <Form.Label>Lastname</Form.Label>
              <Form.Control
               required="required"
               name="lastname"
               type="text"
               value={this.state.lastname}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
               required="required"
               name="email"
               type="text"
               value={this.state.email}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
               required="required"
               name="phone"
               type="text"
               value={this.state.phone}
               onChange={this.handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formOrganization">
              <Form.Label>Organization</Form.Label>
              <Form.Select
               required="required"
               name="organization_id"
               type="text"
               value={this.state.organization_id}
               onChange={this.handleInputChange}
              >
               <option value="">Choose organization</option>

               {organizationList}

               </Form.Select>
            </Form.Group>


            <Button type="submit">Submit</Button>

            </Col>
           </Row>


        <Row className="mt-3">
         <Col>
          {errorsave}
         </Col>
        </Row>


        </Container>
           </Form>

        </div>
      );
    }


  }

}


export function Skipper() {
  // params contain id of user, and is passed in the url. PMB 2021-01-05
  let params = useParams();

  return (
    <Container>
    <Row>
    <Col>
      <h2>Skipper</h2>
    </Col>
    </Row>

    <Row>
      <Col>
        <SkipperProfile params={params}/>
      </Col>
    </Row>
    </Container>

  );
}

